<template ref="login">
  <el-row class="login content-center-middle">
    <el-col
      :xs="24"
      :sm="{ span: 22, offset: 1 }"
      :lg="{ span: 18, offset: 3 }"
      :xl="{ span: 14, offset: 5 }"
    >
      <el-row>
        <el-col :xs="24" :sm="24"><h3 style="text-align:left; padding-left: 30px; color: lightgrey;font-size: 36px;">{{$t('company.title')}}</h3></el-col>
        <el-col :xs="{ span: 24}" :sm="{ span: 10, offset: 14}" style="min-height: 520px;">
          <div class="login-container" v-loading="loading" element-loading-background="rgba(17, 37, 74, 0.8)">
            <div class="title">
              <span>{{ $t("company.login.title") }} </span>
            </div>
            <router-view @triggerLoading="triggerLoading" />
          </div>
          <br />
          <el-alert class="browserSupport" type="info" :closable="false">
            <BrowserSupport type="company" />
          </el-alert>
        </el-col>
      </el-row>
      
      <!-- <el-card class="login-card">
        <template #header>
           <el-row>
            <el-col :span="24">
              <h2 class="nomargin">Company Management Portal</h2>
            </el-col>
          </el-row>
        </template>
       
        <el-row :gutter="50">
          <el-col :span="14" style="text-align: center;">
            <div style="width: 100%;min-width: 30vh;min-height:100%;border: 1px solid red;border-width:thick;"></div>
          </el-col>
          <el-col :span="10">
            
            <br />
            <el-alert class="browserSupport" type="info" :closable="false">
              <BrowserSupport type="company" />
            </el-alert>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px;">
          <el-col :xs="24" :sm="12">
            <h3>FAQ</h3>
          </el-col>
          <el-col :xs="24" :sm="12">
            
          </el-col>
        </el-row>
      </el-card> -->
    </el-col>
  </el-row>
</template>

<style>
.el-row.login {
  padding: 20px;
  min-height: calc(100vh - 120px);
  background-image: url("../../assets/company_login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.login-container {
  background-color: rgba(17, 37, 74, 0.9);
  color: white;
  border-radius: 5px;
  padding: 20px 30px;
}

.login-container > div {
  margin-top: 20px;
}

.login-container > div > label {
  display: block;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
}

.login-container > .title {
  position: relative;
  margin-top: 0px;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
}

.login-container > .title > span {
  position: absolute;
  background-color: #3e5990;
  border-radius: 0px 5px 5px 0px;
  display: block;
  min-width: 10px;
  left: -30px;
  padding: 0px 30px;
}

.verify-code > .el-button {
  width: 100%;
}

.login-card {
  --el-card-bg-color: rgba(243,243,243,0.8);
  --el-card-border-color: rgba(243,243,243,1);
}
.login-card .el-card__header {
  --el-card-border-color: darkgray;
  --el-card-bg-color: rgba(243,243,243,0.8);
}
.login.content-center-middle {
  justify-content: unset;
}

</style>

<script>
import "@/style/common.css"
import BrowserSupport from "@/components/BrowserSupport.vue";
import { authenticateJWT } from "../../libs/common";


export default {
  name: "CompanyLogin",
  components: {
    BrowserSupport
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    triggerLoading(status) {
      this.loading = status
    },
    checkAccess() {
      let jwt = this.$store.getters.companyJWT;
      if(authenticateJWT(jwt)) {
        if(!this.$store.state.company.agreement) {
          this.$router.push({name: "CompanyAgreement"})
          return;
        }
        this.$router.push({name: "CompanyProfile"})
      }
    }
  },
  created() {
    this.checkAccess();
  },
  beforeRouteUpdate() {
    this.checkAccess();
  }
};
</script>
